<template>
  <div
    class="pn-hat"
  >
    <div
      class="relative container"
      :class="{ 'pn-hat--short': !showForm }"
    >
      <div class="relative d-flex flex-column">
        <d-breadcrumbs
          :items="crumbsItems"
          :dark="true"
        />
        <div class="pn-hat__side">
          <h1
            class="pn-hat__h1 headline"
            :class="{ mt: $route.path === '/' }"
            v-html="h1"
          />
          <p
            v-if="promoText"
            class="pn-hat__special white--text"
          >
            Специальное предложение
          </p>
          <div
            v-if="promoText"
            class="pn-hat__tariff d-flex align-center"
          >
            <div class="d-flex flex-column pn-hat__tariff-price align-start">
              <p class="title white--text">
                {{ promoText.bonus }}
              </p>
              <p class="pn-hat__price white--text">
                {{ promoText.price }}
              </p>
            </div>
            <div
              v-if="showForm"
              class="pn-hat__service d-flex flex-wrap"
            >
              <div
                v-for="service in services.filter((el) => !!el.text)"
                :key="service.id"
                class="pn-hat__service-item title-2 white--bg red--text d-flex align-center"
              >
                <img
                  v-if="service.icon"
                  loading="lazy"
                  :src="service.icon"
                  class="d-flex"
                  alt="close"
                >
                {{ service.text }}
              </div>
            </div>
          </div>
          <p
            v-else-if="h2"
            class="p-hat__h2 white--text"
            v-html="h2"
          />
          <div
            v-if="showForm"
            class="pn-hat__actions d-flex align-center"
          >
            <d-btn
              class="pn-hat__actions-connect title-4"
              color="custom"
              size="medium"
              @click="connect"
            >
              Подключить
            </d-btn>
            <button
              class="pn-hat__actions-consult d-flex align-center title-4"
              @click="consult"
            >
              Консультация
            </button>
          </div>
        </div>
        <div
          v-if="showForm && provider.provider?.icon_large"
          class="pn-hat__img"
        >
          <img
            loading="lazy"
            :src="
              imgUrl(
                provider.provider?.icon_large,
                disabledPortal ? 600 : 400,
                0,
              )
            "
            alt="img-large"
          >
        </div>
      </div>
    </div>

    <LazyPartnersSearchForm
      v-if="showForm"
      :loading-providers="loadingProviders"
      :providers-ids="providersIds"
      class="white--bg"
    />
  </div>
</template>

<script setup lang="ts">
import { useMainStore } from '~/store/main/main.store'
import { counter, imgUrl } from '~/helpers'

const props = defineProps({
  h1: {},
  h2: {},
  promoText: {},
  providersIds: {},
  crumbsItems: {},
  showForm: {
    type: Boolean,
    default: true,
  },
  loadingProviders: {
    type: Boolean,
  },
})

const ctx = useNuxtApp()
const mainStore = useMainStore()
const disabledPortal = computed(() => mainStore.disabledPortal)

const services = computed(() => {
  return [
    {
      icon: 'globe.svg',
      text: provider.value?.provider.id === 1 ? props.promoText?.internetSpeed ? `${props.promoText?.internetSpeed} Мбит/с` : '' : props.promoText?.internet ? `${props.promoText?.internet} ГБ` : '',
      icon: '/media/uploads/blog/globe_red.svg',
      text: props.promoText?.internet ? `${props.promoText?.internet} ГБ` : '',
      id: 1,
    },
    {
      icon: provider.value?.provider.id === 1 ? 'tv.svg' : 'phone-thin.svg',
      text: provider.value?.provider.id === 1 ? props.promoText?.tv ? `${props.promoText?.tv} Канал${counter(props.promoText?.tv)}` : '' : props.promoText?.minutes ? `${props.promoText?.minutes} мин` : '',
      icon: '/media/uploads/blog/phone-thin_red.svg',
      text: props.promoText?.minutes ? `${props.promoText?.minutes} мин` : '',
      id: 2,
    },
    {
      icon: provider.value?.provider.id === 1 ? 'phone-thin.svg' : 'comment.svg',
      text: provider.value?.provider.id === 1 ? props.promoText?.internet ? `${props.promoText?.internet} ГБ ${props.promoText?.minutes ? props.promoText?.minutes + ' мин' : ''}` : '' : props.promoText?.sms ? `${props.promoText?.sms} SMS` : '',
      icon: '/media/uploads/blog/comment_red.svg',
      text: props.promoText?.sms ? `${props.promoText?.sms} SMS` : '',
      id: 3,
    },
    {
      img: props.promoText?.winkImage || undefined,
      icon: '',
      text: props.promoText?.wink || 'Интернет и ТВ дома бесплатно',
      text: 'ИНТЕРНЕТ И ТВ ДОМА БЕСПЛАТНО',
      id: 4,
    },
  ]
})

const provider = computed(() => mainStore.getDomainConfig)

const connect = () => {
  ctx.$event('callDialogPartner', true)
}

const consult = () => {
  ctx.$event('consultDialog', true)
}
</script>

<style scoped lang="scss">
.pn-hat {
  background: linear-gradient(
    110deg,
    #8e66e4 1.8%,
    #8f97de 46.74%,
    #959af1 80.15%,
    #c1d8fb 111.24%
  );
  position: relative;
  & > .relative {
    z-index: 1;
  }

  &__mts {
    position: absolute;
    width: 119px;
    height: 119px;
    right: -40px;
    top: 0;
    @media (max-width: getBreakpoint(desktop)) {
      right: -16px;
    }
    @media (max-width: getBreakpoint(tablet)) {
      width: 78px;
      height: 78px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      width: 68px;
      height: 68px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      right: -8px;
      width: 60px;
      height: 60px;
    }
  }
  &--short {
    padding-bottom: 28px;
    .pn-hat__h1 {
      margin-top: 32px;
    }
  }

  &__h1 {
    color: var(--font_offer);
    margin-top: 48px;
    margin-bottom: 32px;
    max-width: 744px;
    white-space: pre-wrap;
    text-transform: uppercase;
    padding-right: 64px;
    @media (min-width: getBreakpoint(desktop)) {
      font-size: 40px;
    }
    @media (max-width: getBreakpoint(desktop)) {
      padding-right: 40px;
    }
    @media (max-width: getBreakpoint(tablet)) {
      margin-top: 56px;
      margin-bottom: 24px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      margin-top: 24px;
      margin-bottom: 20px;
    }

    &.mt {
      margin-top: 108px;
      @media (max-width: getBreakpoint(tablet)) {
        margin-top: 40px;
      }
    }

    &.full-width {
      max-width: unset;
      margin-bottom: 24px;
    }
  }
  &__special {
    font-size: 36px;
    line-height: 1.4;
    font-weight: 400;
    margin-bottom: 8px;
    @media (max-width: getBreakpoint(tablet)) {
      font-size: 24px;
      margin-bottom: 4px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      font-size: 22px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      font-size: 20px;
    }
  }
  &__tariff {
    margin-bottom: 4px;
    @media (max-width: getBreakpoint(desktop)) {
      flex-direction: column;
      align-items: flex-start;
    }

    &-price {
      margin-right: 32px;
      p:first-child {
        max-width: 220px;
        min-width: 200px;
      }
    }
  }

  &__price {
    font-size: 60px;
    line-height: 1.3;
    font-weight: 700;
    position: relative;
    display: inline-block;

    @media (max-width: getBreakpoint(tablet)) {
      font-size: 48px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      font-size: 46px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      font-size: 44px;
    }

    &:before {
      content: "₽";
      font-size: 36px;
      position: absolute;
      right: -40px;
      top: 11px;
      line-height: 1;

      @media (max-width: getBreakpoint(tablet)) {
        font-size: 32px;
        top: 8px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        font-size: 24px;
        right: -33px;
        top: 10px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        font-size: 22px;
        right: -32px;
        top: 11px;
      }
    }
    &:after {
      content: "мес";
      font-size: 14px;
      line-height: 1;
      position: absolute;
      right: -40px;
      top: 45px;
      border-top: 1px solid color(white);
      @media (max-width: getBreakpoint(tablet)) {
        font-size: 13px;
        top: 36px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        font-size: 12px;
        right: -35px;
        top: 32px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        font-size: 12px;
        top: 31px;
      }
    }
  }

  &__h2 {
    color: var(--font_offer);
    font-size: fontSize(headline-8);
    line-height: 1.4;
    margin-bottom: 32px;
    white-space: pre-wrap;
    @media (max-width: getBreakpoint(tablet)) {
      font-size: fontSize(title);
      line-height: 1.5;
      margin-bottom: 24px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      font-size: 15px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      font-size: fontSize(paragraph);
    }
  }

  &__service {
    color: var(--font_offer);
    max-width: 550px;
    &-item {
      font-weight: 700;
      margin-right: 8px;
      margin-bottom: 8px;
      min-height: 40px;
      padding: 8px 16px;
      border-radius: 40px;
      white-space: pre-wrap;
      @media (max-width: getBreakpoint(tablet)) {
        min-height: 24px;
        padding: 0 8px;
        font-size: 14px !important;
        margin-right: 4px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        min-height: 22px;
        font-size: 13px !important;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        min-height: 20px;
        font-size: 12px !important;
      }
      img {
        margin-right: 8px;
        width: 24px;
        height: 24px;
        @media (max-width: getBreakpoint(tablet)) {
          margin-right: 4px;
          width: 20px;
          height: 20px;
        }
        @media (max-width: getBreakpoint(mobile-lg)) {
          width: 18px;
          height: 18px;
        }
        @media (max-width: getBreakpoint(mobile-md)) {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  &__promo {
    margin-bottom: 12px;
    margin-top: 16px;
    color: var(--font_offer);
    span {
      margin-right: 16px;
    }
  }

  &__img {
    position: absolute;
    bottom: 12px;
    top: 80px;
    right: 0;
    max-height: 387px;
    max-width: calc(100vw - 730px);
    overflow: hidden;
    img {
      object-fit: contain;
      object-position: right;
      max-height: 100%;
      @media (max-width: getBreakpoint(tablet)) {
        object-fit: cover;
        object-position: top;
        border-radius: 12px;
        width: 450px;
        max-width: 100%;
        height: 240px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        height: 220px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        height: 200px;
      }
    }
    @media (max-width: getBreakpoint(wide)) {
      max-height: 330px;
      right: -15px;
    }
    @media (max-width: getBreakpoint(desktop)) {
      max-width: calc(100vw - 530px);
      top: unset;
      bottom: 15px;
      right: 0;
      height: 260px;
    }
    @media (max-width: getBreakpoint(tablet)) {
      position: static;
      //right: 50%;
      //transform: translateX(50%);
      height: 240px;
      width: 100%;
      max-width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 24px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      height: 220px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      height: 200px;
      margin-bottom: 20px;
    }
  }

  &__actions {
    margin-top: 20px;
    margin-bottom: 100px;
    @media (max-width: getBreakpoint(desktop)) {
      //margin-bottom: 232px;
      margin-bottom: 60px;
    }
    @media (max-width: getBreakpoint(tablet)) {
      flex-direction: column;
      align-items: center;
      margin-top: 24px;
      margin-bottom: 24px;
      //margin-bottom: 280px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      //margin-bottom: 264px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      margin-bottom: 20px;
      //margin-top: 24px;
      //margin-bottom: 232px;
    }

    &-connect {
      background-color: var(--mainColor);
      color: var(--font_offer);
      padding: 16px 40px;
      border-radius: 12px;
      margin-right: 24px;
      transition: opacity 0.24s;
      text-transform: uppercase;
      &:hover {
        opacity: 0.9;
      }
      @media (max-width: getBreakpoint(tablet)) {
        width: 100%;
        max-width: 400px;
        margin-right: 0;
        height: 44px;
        font-size: 16px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        font-size: 15px;
      }
    }
    &-consult {
      color: var(--font_offer);
      transition: opacity 0.24s;
      text-transform: uppercase;
      position: relative;
      &:before {
        content: '';
        width: 8px;
        height: 8px;
        position: absolute;
        right: -22px;
        top: calc(50% - 6px);
        transform: rotate(-45deg);
        border-right: 1px solid currentColor;
        border-bottom: 1px solid currentColor;
      }
      &:hover {
        opacity: 0.8;
      }
      @media (max-width: getBreakpoint(tablet)) {
        margin-top: 26px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        font-size: 15px;
      }
    }
  }
  &__footer {
    font-weight: 500;
    @media (max-width: getBreakpoint(desktop)) {
      margin-left: -16px;
    }
    @media (max-width: getBreakpoint(tablet)) {
      padding-bottom: 0px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      margin-left: -8px;
    }
    img {
      margin-right: 12px;
      @media (max-width: getBreakpoint(mobile-lg)) {
        width: 35px;
        height: 35px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        width: 32px;
        height: 32px;
      }
    }
    p {
      padding-left: 40px;
      position: relative;
    }
  }
}
</style>
